.count-teams {
  padding: 15px;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;

  .users {
    width: 45%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .users-list {
      flex: 1;
      overflow: auto;
      max-height: 370px;
      margin-top: 8px;
    }
  }

  .teams {
    width: 45%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .teams-list {
      flex: 1;
      overflow: auto;
      max-height: 370px;
      margin-top: 8px;
    }
  }
}
