@import '../../../styles/variables';

.terms-accept-container {
  height: auto;
}

.terms-text-container {
  width: 60%;
}

.terms-text {
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  margin: 0;
  color: $color-grey;
}

.login-aside {
  width: 100% !important;
}

.blue-text {
  color: $color-main !important;
}
