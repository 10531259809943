.page {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .header {
    height: 70px;
  }

  .main {
    flex: 1;
  }
}
