@import '../styles/variables';

.main-container {
  display: flex;
  width: 100%;
  height: 100%;

  .page-aside-container {
    width: 15%;
    height: 100%;
    background-color: #f5f7fb;
    border-right: solid 1px #e9e9e9;
  }

  .page-main-container {
    width: 85%;
    height: 100%;
    background-color: $color-white;
    display: flex;
    justify-content: center;

    @media screen and (max-width: 956px) {
      width: 100% !important;
      height: 60% !important;
    }
  }
}
