@import '../../../styles/variables';

.login-page {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;

  @media screen and (max-width: 956px) {
    height: 60%;
    width: 100%;
  }

  .main-login-container {
    flex: 1;
    padding: 30px;
  }
}
