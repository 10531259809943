.customize-item {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-top: 20px;
  justify-content: space-around;

  &:first-child {
    margin-top: 4px;
  }
}
