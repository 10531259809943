.load-screen {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(227, 230, 233, 0.52);
  z-index: 10000000000;

  > div {
    animation-name: loading;
    animation-duration: 0.8s;
    animation-iteration-count: infinite;
    animation-direction: alternate-reverse;
  }

  .opologic-logo {
    width: 0;
    height: 0;
    border-top: 50px solid transparent;
    border-left: 100px solid #00aaef;
    border-bottom: 50px solid transparent;
  }
}

@keyframes loading {
  from {
    opacity: 0.3;
    width: 4rem;
    height: 4rem;
  }
  to {
    opacity: 1;
    width: 8rem;
    height: 8rem;
  }
}
