@import '../../../../../styles/variables';

.drawer-container {
  width: 100%;
  height: 100%;
  padding: 28px;
  max-height: 800px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  .drawer-title {
    color: $color-dark;
    font-size: 16px;
    font-weight: bold;
  }

  .location-details {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .address-input {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .region-postcode {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .location-note {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .sub-locations-select {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 10%;
    width: 100%;
    margin-top: 11px;
  }
}

.submit-modal-footer {
  display: flex;
  padding: $lg * 1.5;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid #ededed;
}
