@import '../../../styles/variables';

.users-container {
  display: flex;
  width: 100%;
  height: 100%;

  .users-aside-container {
    width: 20%;
    height: 100%;
    background-color: $color-beige;
    border-right: solid 1px #e9e9e9;
  }

  .users-main-container {
    width: 80%;
    height: 100%;
    background-color: $color-white;
  }
}
