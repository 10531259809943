@import '../../../../../styles/variables';

.drawer-container {
  width: 100%;
  height: 100%;
  padding: 24px;
  max-width: 1000px;
  max-height: 800px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .drawer-title {
    color: $color-dark;
    font-size: 16px;
    font-weight: bold;
  }

  .assign-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;

    .teams,
    .locations {
      display: flex;
      flex-direction: column;
      width: 48%;
      height: 100%;

      .teams-input,
      .locations-input {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      .input-hint {
        font-size: 12px;
        font-weight: bold;
        color: #a8afbe;
      }

      .submit-option {
        width: 100%;
        flex: 1;
        display: flex;
        align-items: flex-end;
        cursor: pointer;
        font-size: 16px;
        font-weight: bold;
        color: $color-grey;
      }

      .teams-list,
      .locations-list {
        display: flex;
        flex-direction: column;
        overflow: auto;

        .team-list-item,
        .location-list-item {
          display: flex;
          justify-content: space-between;
          margin-top: $sm;

          .delete-icon {
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .team-list-text,
          .location-list-text {
            font-weight: bold;
            color: $color-main;
            font-size: 18px;
            display: flex;
            align-items: center;
            text-align: center;
            margin: 0;
            padding: 0;
          }
        }
      }
    }
  }

  .user-details-container {
    height: 90%;

    .personal-data-container {
      display: flex;
      justify-content: space-between;
      //padding-top: 15px;
      //padding-bottom: 15px;
      width: 100%;

      .personal-data-input {
        width: 75%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-between;

        .name-container {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;

          .first-name-input,
          .last-name-input {
            width: 45%;
          }
        }
      }
    }
  }

  .contact-data-container {
    display: flex;
    justify-content: space-between;

    .email-input,
    .phone-input {
      width: 45%;
    }
  }

  .role {
    display: flex;

    .MuiFormControl-root {
      width: 100%;
    }

    .MuiFormGroup-root {
      width: 100%;
      display: flex;
      flex-direction: row !important;
      justify-content: space-between;
    }
  }

  .description-input {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    height: 20%;
  }
}

.user-modal-footer {
  display: flex;
  padding: $lg * 1.5;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #ededed;
}
