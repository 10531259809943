.location-select {
  display: flex;
  flex-direction: column;
}

.sub-location-details {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.sub-location-notes {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.drawer-container {
  width: 100%;
  height: 100%;
  padding: 24px !important;
  max-height: 800px;
  display: flex;
  flex-direction: column;
}
