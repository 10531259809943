.date-picker-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding-bottom: 18px;

  //width: 30%;
  //height: 40px;
  //background-color: #e8e8e8;
  //border-radius: 20px;
  //display: flex;
  //justify-content: space-between;
  //align-items: center;
  //
  //.arrow {
  //  cursor: pointer;
  //  height: 100%;
  //  display: flex;
  //  align-items: center;
  //}
}
