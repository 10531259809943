svg {
  vertical-align: sub;
  margin-right: 5px;
}

.err {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &-text {
    margin: 0;
    color: rgb(255, 0, 0);
    font-size: 12px;
  }
}
