// Colors
$color-main: #00aaef;
$color-dark: #5b667d;
$color-white: #ffffff;
$color-grey: #8b95a8;
$color-beige: #f6f8fa;
$color-success: #15aa1a;
$color-danger: #d32f2f;

// Spacing
$sm: 4px;
$md: 8px;
$lg: 16px;
