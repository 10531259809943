@import './src/styles/variables';

.main-data {
  display: flex;
  width: 100%;
  height: 100%;

  .avatar-container {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .avatar-input {
      display: flex;
      width: 70%;
      align-items: center;
      justify-content: center;
      margin-top: $lg * 2;
    }
  }

  .main-data-fields {
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .input-row {
      display: flex;
      justify-content: space-between;
    }
    .input-container {
      width: 45%;
    }

    .contact-info,
    .personal-info {
      margin-top: $lg;
      padding: 8px;
    }
  }
}

.profile-info {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex: 1;
  margin-top: $lg;

  .main-profile-info {
    width: 50%;
    padding: 8px;

    .profile-position {
      display: flex;
      margin-top: $lg * 2;
    }
  }

  .password-change {
    width: 50%;
    display: flex;
    flex-direction: column;
    padding: 8px 0 8px 16px;

    .reset-input {
      margin-top: $lg * 2;
    }
    .reset-submit {
      display: flex;
      justify-content: flex-end;
      margin-top: $lg * 2;

      .reset-button {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}

.save-button {
  align-self: flex-end;
  justify-self: flex-end;
  margin-top: $lg;
}
