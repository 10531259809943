@import '../../../../../styles/variables';

.main-details-container {
  max-width: 900px;
  display: flex;
  flex-direction: column;
  .drawer-title-container {
    height: 30%;
  }

  .location-details-container {
    margin-top: 36px;

    .location-name-container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .location-name {
        display: flex;
        justify-content: space-between;
      }

      .schedule-frequency-icon {
      }
    }

    .progress-percents {
      font-size: 14px;
      font-weight: normal;
      color: $color-dark;
    }
    .schedule-location-name {
      font-size: 16px;
      font-weight: bold;
      color: $color-dark;
    }
  }
}

.sub-details-container {
  flex: 1;
  max-width: 900px;

  .assign-count-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .teams-count-container,
    .users-count-container {
      width: 100%;
      height: 40%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      max-height: 570px;
      overflow: auto;

      .teams-count-list {
        flex: 1;
      }
    }
  }

  .assign-items-container {
    max-width: 900px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .total-count-items {
      margin-top: $sm;
      width: 100%;
      background: #dee5fb;
      border-radius: 5px 5px 5px 5px;
    }

    .sub-location-select {
      margin-top: 20px;
      margin-bottom: 10px;
    }

    .items-list {
      flex: 1;
      overflow: auto;
      max-height: 330px;

      .scan-item {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 10px;

        .barcode-text {
          font-weight: bold;
          color: #5b667d;
          font-size: 12px;
          padding: 1px;
          border: 3px solid #5b667d;
        }

        .item-ref-lot {
          .ref {
            display: flex;
          }
          .lot {
            display: flex;
          }
        }
      }
    }
  }
}

.drawer-title {
  color: $color-dark;
  font-size: 16px;
  font-weight: bold;
}

.drawer-title-black {
  color: black;
  font-size: 16px;
  font-weight: bold;
}
