.modal-header {
  height: 20%;
  width: 100%;
  border-radius: 12px 12px 0 0;

  .header-title {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 15px;
    align-items: center;

    .new-count,
    .close {
      color: white;
      font-weight: bold;
      font-size: 16px;
    }
  }

  .create-steps-tabs {
    width: 100%;
    padding: 15px;
    display: flex;
    justify-content: space-between;

    .step-tab-item {
      cursor: pointer;

      .step-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .step-circle {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          border: 3px solid white;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 1px;
        }

        .step-circle-active {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          border: 3px solid white;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 1px;
          backdrop-filter: opacity(80%);
        }
      }

      .step-name {
        padding-top: 5px;
        color: white;
        font-weight: bold;
        font-size: 14px;
      }
    }
  }
}

.modal-body {
  height: 70%;
  width: 100%;
}

.modal-footer {
  height: 10%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;

  .previous-next {
    display: flex;
    align-items: center;
  }
}

.hr1 {
  position: relative;
  border: 1px solid white;
  left: 63px;
  top: -55px;
  width: 204px;
  height: 0;
}

.hr2 {
  position: relative;
  border: 1px solid white;
  left: 286px;
  top: -57px;
  width: 219px;
  height: 0;
}

.hr3 {
  position: relative;
  border: 1px solid white;
  left: 524px;
  top: -59px;
  width: 217px;
  height: 0;
}
