@import './src/styles/variables';

.id-location-name {
  flex: 1;
  display: flex;

  .label-text {
    color: $color-dark;
    font-weight: bold;
    font-size: 16px;
  }
}

.count-details {
  width: 100%;
  height: 300px;
  display: flex;

  .notes {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .actions {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    padding: $lg;
  }
}

.progress-percent {
  font-size: 14px;
  font-weight: normal;
  color: $color-dark;
}
