.count-locations {
  width: 100%;
  height: 100%;
  padding: 15px;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .locations-input {
    width: 100%;
  }

  .count-sub-locations {
    display: flex;
    margin-top: 8px;
    height: 60px;
    align-items: flex-end;
    justify-content: space-between;

    .count-sub-location-select {
      width: 50%;
    }
  }

  .sub-locations-list {
    flex: 1;
    width: 100%;
    margin-top: 8px;
    max-height: 500px;
    overflow: auto;
  }
}
