@import '../../../../../styles/variables';

.drawer-container {
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  .drawer-title {
    color: $color-dark;
    font-size: 16px;
    font-weight: bold;
  }

  .team-details {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 30%;
  }

  .members-select {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 10%;
    width: 100%;
  }

  .members-list {
    max-height: 500px;
    overflow: auto;
  }

  .user-info {
    width: 60%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .user-info-item {
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-top: $lg;
    }

    .avatar {
      width: 15%;
    }

    .user-name {
      width: 60%;
      font-size: 18px;
      font-weight: bold;
      color: $color-main;
    }

    .user-email {
      width: 25%;
      font-size: 14px;
      font-weight: bold;
      color: $color-dark;
    }
  }

  .delete-icon {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.submit-modal-footer {
  display: flex;
  padding: $lg * 1.5;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid #ededed;
}
