@import '../../../styles/variables';

.hint-container {
  padding: 20px;
}

.reset-hint-text {
  font-size: 14px;
  font-weight: bold;
  color: $color-grey;
}

.reset-message-container {
  height: 50%;
}

.reset-message-text {
  font-weight: bold;
  margin-left: 10px;
  margin-bottom: 0;
}

.form-container {
  width: 70%;
}
