@import '../../../styles/variables';

.submit-container {
  padding-top: 20px;
}

.subtitle-container {
  padding: 20px;
  width: 60%;

  .subtitle-text {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: $color-dark;
  }
  @media screen and (max-width: 956px) {
    display: none;
  }
}

.sign-up-verify-info {
  padding-top: 20px;
  width: 100%;
  @media screen and (max-width: 956px) {
    display: none;
  }

  .sign-up-verify-text {
    font-size: 14px;
    font-weight: bold;
    color: $color-grey;
  }
}
