@import '../../../styles/variables';

.login-aside {
  width: 100%;
  height: 100%;
  background-image: url('../../../assets/icons/opologic_logo_icon.svg');
  background-size: 160% 96%;
  background-repeat: no-repeat;
  background-position: right top;
  border-right: #4097b8 1px solid;
  background-color: #00aaef;

  .business-aside-container {
    min-height: 600px;
    .info-list {
      height: 50%;
      width: 100%;
      padding: 20px;

      .scan-text {
        font-weight: bold;
        font-size: 32px;
        color: white;
      }
      .opologic-information-list {
        .line {
          border-radius: 3px;
          position: relative;
          top: -162px;
          left: 10px;
          margin: 0;
          height: 140px;
          width: 4px;
          background: #ffffff;
        }
        .user-info-list-item {
          padding-top: 20px;

          .owner-info-text {
            height: 100%;
            padding-left: 20px;
            margin: 0;
            font-weight: bold;
            font-size: 14px;
            color: $color-white;
          }

          .full-name-text-info {
            .full-name-label {
              font-weight: bold;
              font-size: 12px;
              color: #7ad5f7;
              margin: 0;
              padding-left: 44px;
            }

            .full-user-name {
              height: 100%;
              padding-left: 44px;
              margin: 0;
              font-weight: bold;
              font-size: 14px;
              color: $color-white;
            }
          }
        }
      }
    }
    .opologic-logo {
      height: 20%;
      padding: 40px;

      .powered-by-text {
        font-weight: bold;
        font-size: 16px;
        color: white;
      }

      .opologic-mono-logo {
        width: 40%;
        min-width: 16px;
        height: 40%;
      }
    }
  }
}
