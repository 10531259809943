@import '../../../styles/variables';

.header-logo {
  background-color: $color-main;
  width: 70px;
  height: 70px;
  border-radius: 0 50px 50px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .logo {
    width: 30px;
    height: 30px;
    opacity: 1 !important;
  }
}

.header-body {
  padding-left: 0 !important;
  margin-left: 0 !important;
  display: flex;
  justify-content: space-between;
}

.horizontal-line {
  height: 50px;
  background-color: #e9e9e9;
  width: 1px;
}

.tabs-control {
  display: flex;
  height: 70px;
  box-shadow: none !important;
  justify-content: space-around !important;
  align-items: stretch;
  .tab {
    color: #707070;
    font-size: 16px !important;
    font-weight: bold;
    align-self: flex-end !important;
    height: 70px;
  }
}

.user {
  color: #8a94a7;
  font-weight: bold !important;
  font-size: 14px;
  height: 100%;
  display: flex;
  align-items: center;
}

.link {
  text-decoration: none !important;
}

.tenant-logo-container {
  display: flex;
  width: calc(15% + 4px);
  align-items: center;
  justify-content: space-between;
}
