@import '../../../styles/variables';

.form-container {
  width: 60%;
  min-width: 300px;
  padding: 10px;

  .Mui-selected {
    color: white !important;
  }

  @media screen and (max-width: 400px) {
    width: 90%;
  }
}

.submit-business-container {
  padding: $md;
  flex: 1;
  @media screen and (max-width: 400px) {
    width: 90%;
  }
}

.subtitle-container {
  padding: $md;
  width: 70%;

  .subtitle-text {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    color: $color-dark;
  }
}

.business-info {
  padding-left: $md;

  .sign-info-text {
    font-size: 14px;
    font-weight: bold;
    color: $color-grey;
  }
}

.business-title {
  font-weight: bold;
  color: $color-dark;
}

.business-form-hint {
  font-weight: bold;
  font-size: 14px;
  color: $color-grey;
}

//.Mui-selected {
//  color: white !important;
//}
