.count-info {
  height: 100%;
  width: 100%;
  padding: 15px;
  display: flex;
  flex-direction: column;

  .count-info-inputs {
    width: 100%;
    margin-top: 15px;
  }

  .count-date-pick {
    width: 100%;
    display: flex;
    align-items: center;
    flex: 1;
  }
}

.MuiPickersTimePickerToolbar-hourMinuteLabel-3903 {
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  width: 100% !important;
}

.MuiPickersTimePickerToolbar-hourMinuteLabel-3903 {
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  width: 100% !important;
}
