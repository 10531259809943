@import '../../styles/variables';

.tabs-list {
  width: 100%;
  padding: 36px $lg $lg * 2 $lg * 1.5;

  .tab-container {
    &:not(:first-child) {
      margin-top: $lg * 1.5;
    }
  }
}
