@import '../../../../../styles/variables';

.drawer-container {
  width: 100%;
  height: 100%;
  padding: $lg * 1.5;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  .drawer-title {
    color: $color-dark;
    font-size: 16px;
    font-weight: bold;
  }
  .schedule-locations {
    height: 100%;
    .schedule-sub-locations {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .schedule-sub-location-select {
        width: 50%;
      }

      .add-sub-location {
        width: 30%;
      }
    }

    .sub-locations-list {
      flex: 1;
      width: 100%;
      max-height: 500px;
      overflow: auto;
    }
  }

  .schedule-details {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .label {
    border-bottom: 1px solid #e8e8e8;
    height: 50px;
  }
}

.label {
  border-bottom: 1px solid #e8e8e8;
  height: 50px;
}

.submit-modal-footer {
  display: flex;
  padding: $lg * 1.5;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid #ededed;
}

@import '../../../../../styles/variables';
