@import '../../../styles/variables';

.dashboard-charts {
  margin-top: 10px;
  height: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;

  .users-chart {
    width: 30%;
    display: flex;
    flex-direction: column;

    .users-chart-container {
      flex: 1;
    }
  }

  .counts-chart {
    width: 50%;
    display: flex;
    flex-direction: column;

    .bar-chart-container {
      position: relative;
      right: 30px;
      flex: 1;
      display: flex;
      align-items: center;
    }
  }

  .items-statistic {
    display: flex;
    flex-direction: column;
    width: 20%;
  }
}

.last-counts-table {
  height: 10%;
}

.text-grey-simple {
  font-weight: bold;
  font-size: 16px;
  color: $color-dark;
  align-self: start;
}

.text-grey-little {
  font-size: 12px;
  font-weight: bold;
  color: $color-grey;
}

.text-grey-big {
  font-size: 24px;
  font-weight: bold;
  color: $color-grey;
}

.custom-tooltip {
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border: 1px solid #e9e9e9;
}
