@import './variables';

.container {
  height: 100%;
  width: 100%;
}

.d-flex {
  display: flex;
}

.flex-start {
  justify-content: flex-start;
}
.flex-center {
  justify-content: center;
}
.flex-end {
  justify-content: flex-end;
}
.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.flex-1 {
  flex: 1;
}

.space-between {
  justify-content: space-between !important;
}
.space-around {
  justify-content: space-around !important;
}
.align-center {
  align-items: center !important;
}
.align-start {
  align-items: flex-start !important;
}
.align-end {
  align-items: flex-end !important;
}
.direction-column {
  flex-direction: column;
}
.direction-row {
  flex-direction: row;
}

.w-100 {
  width: 100%;
}

.w-50 {
  width: 50%;
}

.w-40 {
  width: 40%;
}

.w-60 {
  width: 60%;
}

.w-80 {
  width: 80%;
}

.w-90 {
  width: 90%;
}

.w-70 {
  width: 70%;
}

.w-30 {
  width: 30%;
}

.h-100 {
  height: 100%;
}
.h-40 {
  height: 40%;
}

.h-50 {
  height: 50%;
}

.h-30 {
  height: 30%;
}

.p-10 {
  padding: 10px;
}

.p-20 {
  padding: 20px;
}

.p-40 {
  padding: 40px;
}

.m-0 {
  margin: 0;
}

.page-container {
  background: #ffffff;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.tab-text {
  color: $color-dark;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
}

.position-relative {
  position: relative;
  z-index: 1000;
}

.tab-text:active {
  color: $color-main;
}

.tab-text-active {
  color: $color-main;
  font-weight: bold;
  font-size: 16px;
}

.main-color-text {
  color: $color-main;
}

.form-container {
  width: 60%;
  min-width: 300px;
  max-width: 600px;
  padding: 10px;
  @media screen and (max-width: 820px) {
    width: 90% !important;
  }
}

.subtitle-text {
  color: $color-main;
  font-weight: bold;
  font-size: 16px;
}

.table-text {
  color: $color-dark;
  font-size: 14px;
  font-weight: bold;
}

.small-text {
  color: $color-grey;
  font-size: 12px;
  font-weight: bold;
}

.table-column {
  margin-left: 10px;
}

.d-none {
  display: none;
}

.m-sm {
  margin: $sm !important;
}
.m-b-sm {
  margin-bottom: $sm !important;
}
.m-t-sm {
  margin-top: $sm !important;
}
.m-l-sm {
  margin-left: $sm !important;
}
.m-r-sm {
  margin-right: $sm !important;
}

.m-md {
  margin: $md !important;
}
.m-b-md {
  margin-bottom: $md !important;
}
.m-t-md {
  margin-top: $md !important;
}
.m-l-md {
  margin-left: $md !important;
}
.m-r-md {
  margin-right: $md !important;
}

.m-lg {
  margin: $lg !important;
}
.m-b-lg {
  margin-bottom: $lg !important;
}
.m-t-lg {
  margin-top: $lg !important;
}
.m-l-lg {
  margin-left: $lg !important;
}
.m-r-lg {
  margin-right: $lg !important;
}

.padding-0 {
  padding: 0 !important;
}

.p-sm {
  padding: $sm !important;
}
.p-b-sm {
  padding-bottom: $sm !important;
}
.p-t-sm {
  padding-top: $sm !important;
}
.p-l-sm {
  padding-left: $sm !important;
}
.p-r-sm {
  padding-right: $sm !important;
}

.p-md {
  padding: $md !important;
}
.p-b-md {
  padding-bottom: $md !important;
}
.p-t-md {
  padding-top: $md !important;
}
.p-l-md {
  padding-left: $md !important;
}
.p-r-md {
  padding-right: $md !important;
}

.p-lg {
  padding: $lg !important;
}
.p-b-lg {
  padding-bottom: $lg !important;
}
.p-t-lg {
  padding-top: $lg !important;
}
.p-l-lg {
  padding-left: $lg !important;
}
.p-r-lg {
  padding-right: $lg !important;
}

.align-text-center {
  text-align: center;
}

.cursor-pointer {
  cursor: pointer;
}

.bg-white {
  background: white;
}

.table-black-text {
  font-weight: bold;
  font-size: 16px;
  color: #333333;
}

.font-bold {
  font-weight: bold;
}
