.title-text {
  font-weight: bold;
  font-size: 32px;
  text-align: center;
  width: 80%;

  @media screen and (max-width: 956px) {
    font-size: 24px;
  }
}

.sign-text-container {
  height: 37% !important;
}
