@import '../../../styles/variables';

.login-aside {
  width: 100%;
  height: 100%;
  background-image: url('../../../assets/icons/opologic_logo_icon.svg');
  background-size: 110% 130%;
  background-repeat: no-repeat;
  background-position: left bottom;
  border-right: #4097b8 1px solid;
  background-color: $color-main;

  .opology-info-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    @media screen and (max-width: 956px) {
      justify-content: flex-start;
    }

    .info-list {
      height: 50%;
      width: 100%;
      padding: 30px;

      @media screen and (max-width: 956px) {
        padding: 10px !important;
      }

      .scan-text {
        font-weight: bold;
        font-size: 50px;
        color: white;

        @media screen and (max-width: 956px) {
          display: none;
        }
      }
      .opology-information-list {
        .opology-information-list-item {
          padding-top: 20px;

          .item-text {
            color: white;
            font-weight: bold;
            text-align: left;

            @media screen and (max-width: 956px) {
              font-size: 12px;
            }
          }

          .item-icon {
            width: 24px;
            height: 24px;
            margin-right: 10px;
          }
        }
      }
    }
    .opology-logo {
      height: 15%;
      padding: 30px;
      display: flex;

      @media screen and (max-width: 956px) {
        flex: 1;
        padding: 10px;
      }

      .powered-by-text {
        font-weight: bold;
        font-size: 16px;
        color: white;
        @media screen and (max-width: 956px) {
          font-size: 12px;
        }
      }

      .mono-logo-container {
        height: 40%;
        width: 100%;

        .opologic-mono-logo {
          min-width: 16px;
          height: 100%;
        }
      }
    }
  }
}
