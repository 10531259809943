@import '../../styles/variables';

.manager-container {
  padding: $lg * 2;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  max-width: 1200px;

  .manager-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 8%;
    width: 100%;

    .page-title-container {
      display: flex;
      align-items: flex-start;

      .count {
        margin-left: $sm;
        margin-bottom: 0;
        font-size: 20px;
        color: $color-grey;
      }

      .page-title {
        display: flex;
        font-size: 36px;
        width: max-content;
        align-items: flex-start;
        font-weight: bold;
        margin-bottom: 0;
      }

      .date-step-pick {
        margin-left: $sm;
        width: 40%;
        align-self: center;
      }
    }

    .search-container-sub {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      .search-input-sub {
        width: 100%;
        display: flex;
        justify-content: flex-end;

        .search-input-container-sub {
          width: 55%;
          display: flex;
          justify-content: flex-end;
        }
      }
    }

    .search-container {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      .search-input {
        width: 100%;
        display: flex;
        justify-content: flex-end;

        .search-input-container {
          width: 40%;
          display: flex;
          justify-content: flex-end;
        }
      }
    }
    .search-submit {
      display: flex;
      height: 100%;
      align-items: flex-start;
    }
  }

  .table-switch {
    margin-top: $lg;
  }

  .table-filters {
    width: 100%;
    margin-top: $md;
    display: flex;
    align-items: center;

    .MuiInput-underline:before,
    .MuiInput-underline:after {
      border-bottom: 0 !important;
    }

    .filter-container {
      display: flex;
      padding-bottom: 10px;
      width: 25%;

      &.datepicker-container {
        width: 62.5%;
      }

      &.selects-container {
        width: 35%;
      }

      .filter-name {
        margin-right: $sm * 2;
        margin-bottom: 0;
        align-items: center;
      }
    }
  }
}

.table {
  height: 60%;
}

.sub-locations-table {
  height: 30%;
}

.csv-reader-input {
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .csv-label {
    cursor: pointer;
  }
}

.csv-input {
  display: none;
}
